<template>
  <div class="OrderRefund_I">
    <div class="headNavs">
      <div class="headTilte">退款</div>
      <div class="headDetails">说明：包含订单编号、买家昵称、退款时间、退款原因、退款状态</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入订单编号">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        header-cell-class-name="table-header"
        @selection-change="handleSelect">
        <el-table-column prop="id" label="订单ID" width="65" align="center"></el-table-column>
        <el-table-column prop="back_status_text" label="图片" width="80" align="center">
          <template #default="scope">
            <el-image class="Toper"
                      :src="scope.row.goodsone.images" alt=""
                      :preview-src-list="[scope.row.goodsone.images]"
                      :initial-index="0"
                      :hide-on-click-modal=true
                      fit="cover">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="站点" width="65" align="center">
          <template #default="scope">
            <span>{{ scope.row.site ? scope.row.site.name : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_no" label="订单编号" width="195" align="center"></el-table-column>
        <el-table-column prop="nickname" label="买家昵称" width="120" align="center">
          <template #default="scope">
            <span>{{ scope.row.user ? scope.row.user.nickname : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="联系电话" width="110" align="center">
          <template #default="scope">
            <span>{{ scope.row.user ? scope.row.user.mobile : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="服务商" width="100" align="center">
          <template #default="scope">
            <span>{{ scope.row.business ? scope.row.business.username : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="goods_name" label="商品名称" width="165" align="center">
          <template #default="scope">
            <span>{{ scope.row.goodsone ? scope.row.goodsone.goods_name : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="total_num" label="商品数量" width="100" align="center">
          <template #default="scope">
            <span>{{ scope.row.goodsone ? scope.row.goodsone.total_num : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pay_price" label="支付金额" width="80" align="center"></el-table-column>
        <el-table-column prop="back_status_text" label="退款状态" width="90" align="center"></el-table-column>
        <el-table-column prop="cause" label="退款原因" width="110" align="center">
            <template #default="scope">
            <span>{{ scope.row.refund ? scope.row.refund.cause : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createtime" label="申请时间" width="140" align="center"></el-table-column>
        <el-table-column label="操作" align="center"  width="180">
          <template #default="scope">
            <div class="el_butto" v-if="scope.row.back_status != 30">
              <el-button type="text" @click="agreeAdd(scope.row)" >同意退款</el-button>
            </div>
            <div class="el_butto" v-if="scope.row.back_status == 30">
              <el-button class="caese" disabled  type="text">已退款</el-button>
            </div>
            <div class="el_butto" v-if="scope.row.back_status != 10 && scope.row.back_status == 30">
              <el-button type="text" >驳回退款</el-button>
            </div>
            <div class="el_butto" v-if="scope.row.back_status != 10 && scope.row.back_status != 30">
              <el-button type="text" @click="agreeAdd(scope.row)">驳回退款</el-button>
            </div>
            <div class="el_butto" v-if="scope.row.back_status == 10">
              <el-button disabled class="caese" type="text" >已驳回</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      tableData:[],//总列表
      page: {   // 分页
        currentPage: 1,
        pageSize: 10,
      },
      //搜索参数
      search: {
        keyword: "",
      },
    }
  },
  mounted() {
    var that = this
    that.loadTable();
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
  },
  methods: {  
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/order/refund/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if( e == false){
        that.search.keyword = ""
      }
      that.loadTable();
    },
    //同意退款
    agreeAdd(data){
        let dataId = data.id
        var that = this
        that.$confirm("确定同意退款吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'POST',
          url: '/admin/order/refund/refund',
          data: {
            id: data.id
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    // 驳回
    rejectAdd( data){
        let dataId = data.id
        var that = this
        that.$confirm("确定驳回退款吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'POST',
          url: '/admin/order/refund/refundno',
          data: {
            id: data.id
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    }, 
  }
}
</script>

<style scoped>
.TablesList{
  margin: 15px 20px;
}
</style>